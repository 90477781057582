import Breadcrumb, {
  BreadcrumbProps as AntBreadcrumbProps,
} from 'antd/lib/breadcrumb'
import React, { FC } from 'react'
import ChevronRight from 'react-feather/dist/icons/chevron-right'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Color, FontSize, FontWeight } from '..'

const StyledBreadcrumb = styled(Breadcrumb)`
  display: flex;
  margin-bottom: 2rem;

  span {
    display: flex;
    align-items: center;
  }
`

const StyledItem = styled(Breadcrumb.Item)`
  color: ${Color.dark};
  font-size: ${FontSize.md};
  font-weight: ${FontWeight.bold};

  a {
    color: ${({ theme }) => theme.linkColor};
  }

  + .ant-breadcrumb-separator {
    color: ${({ theme }) => theme.linkColor};
    margin: 0;
  }
`

export interface BreadcrumbType {
  title: string
  link?: string
  current?: boolean
}

export interface BreadcrumbsProps extends AntBreadcrumbProps {
  items: BreadcrumbType[]
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  items,
  separator = <ChevronRight size={30} />,
  ...rest
}) => (
  <StyledBreadcrumb separator={separator} {...rest}>
    {items.map((item) => (
      <StyledItem key={item.title}>
        {item.link ? <Link to={item.link}>{item.title}</Link> : item.title}
      </StyledItem>
    ))}
  </StyledBreadcrumb>
)
