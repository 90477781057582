import { AxiosRequestConfig, AxiosResponse } from 'axios'
import useSWR, { SWRConfiguration } from 'swr'

import { ApiError } from '../errors'
import { useApi } from './useApi'

/**
 * Allow to pass `axios` config on configuring `useSWR` hook.
 */
export interface ApiRequestConfig extends SWRConfiguration {
  axiosConfig?: AxiosRequestConfig
}

/**
 * Loading state.
 */
export interface LoadingBag {
  loading: true
}

/**
 * API Request ended with an error.
 */
export interface ErrorBag {
  loading: false
  error: ApiError
}

/**
 * API Request ended without an error.
 */
export interface ApiDataBag<T> {
  loading: false
  apiData: T
  response: AxiosResponse<T>
}

/**
 * Union type of all possible API Response states.
 */
export type ApiResponseBag<T> = LoadingBag | ErrorBag | ApiDataBag<T>

/**
 * Guard function for loading bag.
 */
export const isLoadingBag = (bag: ApiResponseBag<any>): bag is LoadingBag =>
  (bag as LoadingBag).loading

/**
 * Guard function for error bag.
 */
export const isErrorBag = (bag: ApiResponseBag<any>): bag is ErrorBag =>
  (bag as ErrorBag).error !== undefined

/**
 * Guard function for API data bag.
 */
// eslint-disable-next-line func-style
export function isApiDataBag<T>(bag: ApiResponseBag<T>): bag is ApiDataBag<T> {
  return (bag as ApiDataBag<T>).apiData !== undefined
}

/**
 * Wrapper around `useSWR` hook to make API requests with `axios`.
 */
export const useApiRequest = <T>(
  url: string,
  config: ApiRequestConfig = {},
): ApiResponseBag<T> => {
  const api = useApi()
  const { axiosConfig, ...rest } = config

  const fetcher = async (): Promise<AxiosResponse<T>> =>
    await api.request<T>({
      url,
      ...axiosConfig,
    })

  const keyPrefix = axiosConfig?.method ? `${axiosConfig?.method}-` : ''
  const swrConfig: SWRConfiguration = {
    revalidateOnFocus: false,
    ...rest,
  }

  const { data: response, error } = useSWR(
    `${keyPrefix}${url}`,
    fetcher,
    swrConfig,
  )

  if (!error && !response) {
    return { loading: true }
  }
  if (error) {
    return { loading: false, error }
  }
  return { loading: false, response, apiData: response.data }
}
