import Layout, { LayoutProps as HeaderProps } from 'antd/lib/layout'
import styled from 'styled-components'

const { Header: AntHeader } = Layout

export { HeaderProps }

export const Header = styled(AntHeader)`
  background-color: ${({ theme }) => theme.header.backgroundColor};
  color: ${({ theme }) => theme.header.color};

  a {
    color: ${({ theme }) => theme.header.linkColor};
  }

  a:hover {
    color: ${({ theme }) => theme.header.linkHoverColor};
  }
`
