import { Row } from 'antd/lib/grid'
import Layout, { SiderProps as AntSiderProps } from 'antd/lib/layout'
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import ChevronsLeft from 'react-feather/dist/icons/chevrons-left'
import ChevronsRight from 'react-feather/dist/icons/chevrons-right'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import styled, { ThemeContext } from 'styled-components'

import { Color, FontSize, MenuItemType, MenuSectionType, Spacing } from '../..'
import {
  IS_SIDER_COLLAPSED_KEY,
  SIDER_WIDTH,
  SIDER_WIDTH_COLLAPSED,
} from '../../constants'
import { useBreakpoint } from '../../hooks'
import { media } from '../../media'
import { Size } from '../../styles'
import { Text } from '../Typography'
import { SiderMenuItems } from './SiderMenuItems'

const { Sider: AntSider } = Layout

export type SiderItems =
  | {
      menuItems: MenuItemType[]
      sections?: never
    }
  | {
      menuItems?: never
      sections: MenuSectionType[]
    }

export type SiderProps = AntSiderProps & {
  header?: ReactNode
  footer?: ReactNode
  collapse?: boolean
  collapseHeader?: ReactNode
  collapseFooter?: ReactNode
} & SiderItems

export const StyledSider = styled(AntSider)<{ $isSiderOpen?: boolean }>`
  padding: ${Spacing.sm16} ${Spacing.sm8};
  background: ${({ theme }) => theme.sider.backgroundColor};

  .ant-layout-sider-children {
    position: fixed;
    width: ${(props) =>
      props.$isSiderOpen
        ? `calc(${SIDER_WIDTH}px - ${Spacing.sm16})`
        : `calc(${SIDER_WIDTH_COLLAPSED}px - ${Spacing.sm16})`};
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    justify-content: space-between;
    height: ${`calc(100% - ${Spacing.md32})`};

    .ant-typography {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .ant-menu-title-content {
    margin-left: ${Spacing.sm12} !important;
  }

  ${media.down(Size.lg)} {
    text-align: center;

    a,
    .ant-menu-title-content {
      font-size: 0 !important;
    }

    span {
      margin-left: 0 !important;
    }

    .ant-menu-item-icon {
      display: block;
      margin: 0 auto;
    }
  }
`

export const Sider: FC<SiderProps> = ({
  children,
  menuItems,
  sections,
  header,
  footer,
  collapse,
  collapseHeader,
  collapseFooter,
  ...rest
}) => {
  const theme = useContext(ThemeContext)

  const [localStorageValue, setLocalStorageValue, removeLocalStorageValue] =
    useLocalStorage(IS_SIDER_COLLAPSED_KEY)

  const { isDesktop } = useBreakpoint()

  const [isSiderCollapsed, setIsSiderCollapsed] = useState(
    localStorageValue ?? false,
  )

  const handleCollapse = (): void => {
    setIsSiderCollapsed(true)
    setLocalStorageValue(true)
  }

  const handleOpen = (): void => {
    setIsSiderCollapsed(false)
    removeLocalStorageValue()
  }

  const selectedKeys: string[] = sections
    ? sections
        .map((section) =>
          section.menuItems
            .filter(({ selected }) => selected)
            .flat()
            .map(({ key }) => key),
        )
        .flat()
    : menuItems.filter(({ selected }) => selected).map(({ key }) => key)

  const renderSections = (): ReactNode =>
    sections
      ? sections.map((section) => (
          <div
            style={{ marginBottom: Spacing.md24, width: '100%' }}
            key={section.name}
          >
            <Text
              style={{
                color: Color.blue,
                fontSize: FontSize.xs,
                marginLeft: Spacing.sm16,
              }}
            >
              {!isSiderCollapsed && section.name}
            </Text>
            <SiderMenuItems
              menuItems={section.menuItems}
              selectedKeys={selectedKeys}
              key={section.name}
            />
          </div>
        ))
      : null

  useEffect(() => {
    !isDesktop && setIsSiderCollapsed(true)
  }, [isDesktop])

  return (
    <StyledSider
      {...rest}
      theme={theme}
      $isSiderOpen={!isSiderCollapsed}
      width={isSiderCollapsed ? SIDER_WIDTH_COLLAPSED : SIDER_WIDTH}
    >
      <Row
        align={isSiderCollapsed ? 'middle' : 'top'}
        style={{ flexDirection: 'column' }}
      >
        <div
          style={{
            marginBottom: Spacing.sm16,
            paddingLeft: isSiderCollapsed ? 0 : Spacing.sm16,
          }}
        >
          {collapse && isSiderCollapsed ? collapseHeader : header}
        </div>

        {menuItems && (
          <SiderMenuItems menuItems={menuItems} selectedKeys={selectedKeys} />
        )}
        {sections && renderSections()}

        {children}
      </Row>

      <Row style={{ position: 'relative', padding: `0 ${Spacing.sm16}` }}>
        {collapse && isSiderCollapsed ? collapseFooter : footer}

        {collapse && isSiderCollapsed && isDesktop && (
          <ChevronsRight
            color={Color.blue}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              right: Spacing.sm16,
              bottom: 0,
            }}
            onClick={handleOpen}
          />
        )}

        {collapse && !isSiderCollapsed && (
          <>
            <div
              style={{
                position: 'absolute',
                height: '24px',
                width: '1px',
                background: Color.gray,
                right: Spacing.md40,
              }}
            />
            <ChevronsLeft
              color={Color.blue}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: Spacing.sm8,
              }}
              onClick={handleCollapse}
            />
          </>
        )}
      </Row>
    </StyledSider>
  )
}
