import AntTitle, {
  TitleProps as AntTitleProps,
} from 'antd/lib/typography/Title'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Color, FontSize, FontWeight, LineHeight, Size } from '../..'
import { media } from '../../media'

export interface TitleProps extends AntTitleProps {
  $subtitle?: boolean
  $section?: boolean
  $sidebar?: boolean
  $textAlign?: string
}

const StyledTitle = styled(AntTitle)<TitleProps>`
  font-style: normal;
  font-weight: ${FontWeight.extraBold};
  color: ${Color.dark} !important;
  text-align: ${(props) => props.$textAlign ?? props.$textAlign};

  ${(props) =>
    props.$textAlign &&
    css`
      text-align: ${props.$textAlign};
    `}

  ${media.down(Size.sm)} {
    font-size: ${FontSize.lg} !important;
    line-height: ${LineHeight.md} !important;
  }

  ${({ $subtitle }) =>
    $subtitle
      ? css`
          font-weight: ${FontWeight.normal} !important;
          font-size: ${FontSize.md} !important;
          color: ${Color.gray} !important;
          line-height: ${LineHeight.md} !important;
          letter-spacing: -0.2px;
        `
      : undefined} ${({ $section }) =>
    $section
      ? css`
          font-family: 'Roboto Mono', monospace !important;
          font-style: normal;
          font-weight: ${FontWeight.bold} !important;
          font-size: ${FontSize.md} !important;
          line-height: ${LineHeight.sm} !important;
          letter-spacing: -0.01em;
          color: ${Color.blue} !important;
        `
      : undefined} ${({ $sidebar }) =>
    $sidebar
      ? css`
          font-family: 'Roboto Mono', monospace !important;
          font-style: normal;
          font-weight: ${FontWeight.bold} !important;
          font-size: ${FontSize.sm} !important;
          line-height: ${LineHeight.xs} !important;
          letter-spacing: 0.11em;
          text-transform: uppercase;
          color: ${Color.darkGray} !important;
        `
      : undefined}
`

export type TitleLevel = 1 | 2 | 3 | 4 | 5 | undefined

export const Title: FC<TitleProps> = ({
  children,
  $subtitle = false,
  $section = false,
  $sidebar = false,
  ...rest
}) => (
  <StyledTitle
    level={$subtitle || $section || $sidebar ? 3 : 1}
    $subtitle={$subtitle}
    $section={$section}
    $sidebar={$sidebar}
    {...rest}
  >
    {children}
  </StyledTitle>
)
