import React, { ErrorInfo, FC, ReactNode } from 'react'
import Markdown from 'react-markdown'

import { Card, CardProps } from './Card'
import { Title } from './Typography/Title'

export const DEFAULT_ERROR_TITLE = 'Unhandled Error'

const isString = (value: ReactNode | string): value is string =>
  typeof value === 'string'

export interface ErrorCardProps extends CardProps {
  children: ReactNode | string
  errorInfo?: ErrorInfo
}

export const ErrorCard: FC<ErrorCardProps> = ({
  children,
  errorInfo,
  title = DEFAULT_ERROR_TITLE,
  ...rest
}) => (
  <Card title={title} {...rest}>
    {isString(children) ? <Markdown>{children}</Markdown> : children}
    {errorInfo && (
      <>
        <Title level={5}>Traceback</Title>
        <pre>
          <code>{errorInfo.componentStack}</code>
        </pre>
      </>
    )}
  </Card>
)
