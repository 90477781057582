import { AxiosError } from 'axios'
import { FormikHelpers } from 'formik'

import {
  convertErrorToString,
  convertValidationErrorItemLocToString,
  isAxiosError,
  ValidationErrorItemRecord,
  ValidationErrorResponseRecord,
} from '../api'

export type FormErrorsType = Record<string, string>

export const getFormErrors = (
  apiData: ValidationErrorResponseRecord,
): FormErrorsType =>
  apiData.detail.reduce<FormErrorsType>(
    (
      current: FormErrorsType,
      item: ValidationErrorItemRecord,
    ): FormErrorsType => ({
      ...current,
      [convertValidationErrorItemLocToString(item.loc)]: item.msg,
    }),
    {},
  )

export const setErrorStatus = (
  actions: FormikHelpers<any>,
  err: AxiosError | Error,
): void => {
  actions.setSubmitting(false)

  if (isAxiosError(err) && err.response && err.response.status === 422) {
    Object.entries(getFormErrors(err.response.data)).forEach(
      ([field, value]) => {
        actions.setFieldError(field, value)
      },
    )
  } else {
    actions.setStatus(convertErrorToString(err))
  }
}
