export enum Color {
  // Name shortcuts
  white = '#FFFFFF',
  black = '#000000',
  // BG
  light = '#F4F5F9',
  // text
  dark = '#090924',

  lightBlue = '#DFDFF7',
  flowerBlue = '#7371DD',
  // main color
  blue = '#5856D6',
  // link text
  darkBlue = '#14134D',

  // border2
  lightestGray = '#E1E4ED',
  // border
  lightGray = '#AFBED6',
  // text grey
  gray = '#4C5F82',
  // grey dark
  darkGray = '#899AB7',

  // dark slate gray
  darkSlateGray = '#213956',

  red = '#E41C28',

  orange = '#F2994A',

  lightCyan = '#4EFFDF',

  teal = '#0EB8B8',
  green = '#2ED47A',
  darkGreen = '#00AE8F',

  turquoise = '#1BC1DE',
}

const opacities = {
  90: 'E6',
  80: 'CC',
  70: 'B3',
  60: '99',
  50: '80',
  40: '66',
  30: '4D',
  20: '33',
  10: '1A',
  5: '0D',
}

export const colorWithOpacity = (
  color: Color | string,
  opacity: keyof typeof opacities,
): string => color + opacities[opacity]
