import qs, { ParsedQs } from 'qs'

type ParsedQsOrString = ParsedQs | string

const isParsedQs = (value: ParsedQsOrString): value is ParsedQs =>
  typeof value !== 'string'

export const parseSearchParams = (value: string): ParsedQs =>
  value ? qs.parse(value[0] === '?' ? value.slice(1) : value) : {}

export function stringifySearchParams(
  current: string,
  nextParams: ParsedQs,
): string
export function stringifySearchParams(nextParams: ParsedQs): string

// eslint-disable-next-line func-style
export function stringifySearchParams(
  mixed: ParsedQsOrString,
  nextParams?: ParsedQs,
): string {
  const nextSearch = qs.stringify(
    isParsedQs(mixed) ? mixed : { ...parseSearchParams(mixed), ...nextParams },
    { indices: false },
  )
  return nextSearch ? `?${nextSearch}` : ''
}
