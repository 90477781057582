import Row from 'antd/lib/row'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { Color } from '..'
import { AssessmentCard, AssessmentRecord } from './AssessmentCard'

const Empty = styled(Row)`
  color: ${Color.darkGray};
`

export interface AssessmentsListProps {
  emptyText?: ReactNode
  records: AssessmentRecord[]
}

export const AssessmentsList: FC<AssessmentsListProps> = ({
  emptyText = 'No Assessments',
  records,
}) => (
  <>
    {records.length > 0 ? (
      records.map((record) => (
        <AssessmentCard key={record.uid} record={record} />
      ))
    ) : (
      <Empty justify="center" align="middle">
        {emptyText}
      </Empty>
    )}
  </>
)
