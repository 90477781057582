import AntProgress, { ProgressProps } from 'antd/lib/progress'
import React, { FC } from 'react'
import styled from 'styled-components'

import { Color, FontWeight, Spacing } from '..'

export { ProgressProps }

const getProgressStrokeColor = (strokeColor?: string): string | undefined => {
  switch (strokeColor) {
    case 'success':
      return Color.green
    case 'processing':
      return Color.blue
    case 'error':
      return Color.darkGray
    default:
      return strokeColor
  }
}

const Styled = styled.div<{ $progress?: number }>`
  .ant-progress-outer {
    padding-right: 0;
  }

  .ant-progress-text {
    position: absolute;
    top: -${Spacing.sm16};
    text-align: right;
    font-weight: ${FontWeight.extraBold};
    color: ${(props) =>
      props.$progress && props.$progress === 100
        ? Color.darkGreen
        : Color.dark};
    margin-left: ${(props) =>
      props.$progress && props.$progress === 100 ? 0 : '6px'};
  }
`

export const Progress: FC<ProgressProps> = (props) => (
  <Styled $progress={props.percent}>
    <AntProgress
      {...props}
      strokeColor={getProgressStrokeColor(props.strokeColor as string)}
      status="active"
    />
  </Styled>
)
