import moment, { Moment } from 'moment'

import { DATE_FORMAT, EMPTY_TEXT } from '../constants'

export type MixedDate = Moment | Date | string

export const formatDate = (
  value: MixedDate | undefined,
  dateFormat = DATE_FORMAT,
): string => (value ? moment(value).format(dateFormat) : EMPTY_TEXT)

export const formatPastDistance = (value: MixedDate): string =>
  moment(value).fromNow()

export const parseISODate = (value: string): Date => moment(value).toDate()

export const formatISODate = (value: MixedDate): string =>
  moment(value).format('YYYY-MM-DD')

export const formatISODateTime = (value: MixedDate): string =>
  moment(value).toISOString()
