import Col from 'antd/lib/col'
import Popover from 'antd/lib/popover'
import Row from 'antd/lib/row'
import React, { FC } from 'react'
import MoreVertical from 'react-feather/dist/icons/more-vertical'

import { Color, Spacing } from '..'
import { capitalizeFirstLetter, formatDate } from '../functions'
import { Button } from './Button'
import { Card, CardProps } from './Card'
import { Description } from './Description'
import { Pop, PopItemType } from './Pop'
import { Text, Title } from './Typography'

export interface PatientRecord {
  uid: string
  fullName: string
  externalId: string
  birthDate: Date
  gender: string
  admissionDate?: Date
  hasCurrentAssessment: boolean
}

export interface PatientCardProps extends CardProps {
  record: PatientRecord
  onEditProfile: () => void
  onInterviewInvite?: () => void
  onInterviewRemind?: () => void
}

export const PatientCard: FC<PatientCardProps> = ({
  record,
  onEditProfile,
  onInterviewInvite,
  onInterviewRemind,
  ...rest
}) => {
  const getPopItems = (): PopItemType[] =>
    [
      {
        key: 'edit-profile',
        title: 'Edit profile',
        show: true,
        onClick: onEditProfile,
      },
      {
        key: 'interview-reminder',
        title: 'Remind about interview',
        show: record.hasCurrentAssessment,
        onClick: onInterviewRemind,
      },
    ].filter((item) => item.show)

  return (
    <Card {...rest}>
      <Row wrap>
        <Col lg={6} xs={24}>
          <Title level={3}>{record.fullName}</Title>
          <Text>ID: {record.externalId}</Text>
        </Col>

        <Col lg={12} xs={24}>
          <Row>
            <Col lg={8} xs={12}>
              <Description label="Date of birth" layout="vertical">
                {formatDate(record.birthDate)}
              </Description>
            </Col>

            <Col lg={8} xs={12}>
              <Description label="Gender" layout="vertical">
                {capitalizeFirstLetter(record.gender)}
              </Description>
            </Col>

            {record.admissionDate && (
              <Col lg={8} xs={12}>
                <Description label="Admission Date" layout="vertical">
                  {formatDate(record.admissionDate)}
                </Description>
              </Col>
            )}
          </Row>
        </Col>

        <Col
          lg={6}
          xs={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Row justify="center">
            <Button
              disabled={record.hasCurrentAssessment}
              onClick={onInterviewInvite}
              size="large"
              $boxShadow
              style={{ marginBottom: Spacing.sm8 }}
            >
              Invite to an Interview
            </Button>

            <Popover
              content={<Pop items={getPopItems()} />}
              placement="bottomRight"
              trigger="click"
            >
              <Button
                icon={<MoreVertical color={Color.blue} />}
                className="lucid"
                size="large"
              />
            </Popover>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
