import AntRadioButton, { RadioButtonProps } from 'antd/lib/radio/radioButton'
import React, { FC } from 'react'
import styled from 'styled-components'

import { BorderRadius, Color, colorWithOpacity, FontWeight } from '..'

const StyledRadioButton = styled(AntRadioButton)`
  &.ant-radio-button-wrapper {
    border: 1px solid ${({ theme }) => theme.borderColor} !important;
    border-radius: ${BorderRadius.xs} !important;
    color: ${({ theme }) => theme.linkColor};
    text-align: center;
    font-weight: ${FontWeight.bold};

    &:not(:first-child)::before {
      content: none;
    }
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: ${colorWithOpacity(Color.blue, 10)};
    border-color: ${({ theme }) => theme.linkColor} !important;

    &:focus-within {
      outline: 0;
      box-shadow: 0 0 black;
    }
  }
`

export { RadioButtonProps }

export const RadioButton: FC<RadioButtonProps> = ({
  children,
  value,
  ...rest
}) => (
  <StyledRadioButton key={value} value={value} {...rest}>
    {children}
  </StyledRadioButton>
)
