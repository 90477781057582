import BackTop, { BackTopProps } from 'antd/lib/back-top'
import React, { FC } from 'react'
import ChevronUp from 'react-feather/dist/icons/chevron-up'
import styled from 'styled-components'

import { BorderRadius, Color } from '..'
import { Spacing } from '../styles'
import { Button } from './Button'

const StyledButton = styled(Button)`
  background: ${Color.blue};
  border-radius: ${BorderRadius.circle};
  width: 44px;
  height: 44px;
  border: none;
  &:hover {
    background: ${Color.blue};
  }

  svg {
    position: relative;
    vertical-align: middle;
    right: ${Spacing.sm4};
  }
`

export const BackToTop: FC<BackTopProps> = (props) => (
  <BackTop {...props}>
    <StyledButton>
      <ChevronUp />
    </StyledButton>
  </BackTop>
)
