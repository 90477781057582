import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

import { BoxShadow, Color, Spacing } from '..'

const Styled = createGlobalStyle`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    box-shadow: ${BoxShadow.dropdown};
  }
`

const Block = styled.div`
  padding: ${Spacing.sm16} ${Spacing.lg48} ${Spacing.sm16} ${Spacing.sm16};
  color: ${Color.gray};

  &:hover {
    background: ${Color.light};
    color: ${Color.dark};
    cursor: pointer;
  }
`

const BlockLink = styled(Link)`
  display: block;
  color: ${Color.gray};
  padding: ${Spacing.sm16} ${Spacing.lg48} ${Spacing.sm16} ${Spacing.sm16};

  &:hover {
    background: ${Color.light};
    color: ${Color.dark};
    cursor: pointer;
  }
`

export interface PopItemType {
  key: string
  title: ReactNode
  link?: string
  onClick?: () => void
}

export interface PopProps {
  items: PopItemType[]
}

export const Pop: FC<PopProps> = ({ items }) => (
  <>
    <Styled />

    {items.map(({ key, title, link, onClick }) =>
      link ? (
        <BlockLink key={key} to={link}>
          {title}
        </BlockLink>
      ) : (
        <Block onClick={onClick} key={key}>
          {title}
        </Block>
      ),
    )}
  </>
)
