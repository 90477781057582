import AntCard, { CardProps as AntCardProps } from 'antd/lib/card'
import React, { FC } from 'react'
import styled from 'styled-components'

import { BorderRadius, BoxShadow, Color, colorWithOpacity, Spacing } from '..'

export interface CardProps extends AntCardProps {
  $boxShadow?: boolean
  $padding?: string
}

const StyledCard = styled(AntCard)<CardProps>`
  width: 100%;
  border-radius: ${BorderRadius.xs};
  box-shadow: ${({ $boxShadow }) => ($boxShadow ? BoxShadow.regular : 'none')};
  margin-bottom: ${Spacing.sm16};
  > .ant-card-body {
    padding: ${(props) => props.$padding ?? props.$padding};
    > .footer {
      border-top: 1px solid ${colorWithOpacity(Color.lightGray, 30)};
    }
  }
  &: last-child {
    margin-bottom: 0;
  }
`

export const Card: FC<CardProps> = ({ $boxShadow = true, ...rest }) => (
  <StyledCard $boxShadow={$boxShadow} {...rest} />
)
