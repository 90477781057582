import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import React, { FC } from 'react'
import styled from 'styled-components'

import { Color, FontWeight, Size, Spacing } from '..'
import { SHORT_DATE_FORMAT } from '../constants'
import { formatDate } from '../functions'
import { media } from '../media'
import { AssessmentRecord } from './AssessmentCard'
import { Description } from './Description'
import { Progress } from './Progress'

const Section = styled(Description)`
  ${media.down(Size.sm)} {
    margin-bottom: ${Spacing.sm16};
  }
`

const StyledProgress = styled(Progress)`
  &.ant-progress-status-active .ant-progress-bg::before {
    animation: none !important;
  }
`

const RecentActivity = styled(Description)`
  td.ant-descriptions-item {
    display: flex;
    justify-content: flex-end;
  }

  .ant-descriptions-item-content {
    font-weight: ${FontWeight.bold};
  }

  ${media.down(Size.sm)} {
    td.ant-descriptions-item {
      justify-content: flex-start;
    }
  }
`

export const AssessmentProgress: FC<{
  dateFormat?: string
  record: AssessmentRecord
}> = ({ dateFormat = SHORT_DATE_FORMAT, record }) => (
  <>
    {record.currentSection && (
      <Section label="At section" labelColor={Color.dark}>
        {record.currentSection}
      </Section>
    )}

    <StyledProgress
      percent={record.progress}
      strokeColor={record.status.color}
    />

    <Row>
      <Col lg={12} xs={24}>
        <Description label="Invitation Date" labelColor={Color.dark}>
          {formatDate(record.createdAt, dateFormat)}
        </Description>
      </Col>

      {record.lastActivityAt && (
        <Col lg={12} xs={24}>
          <RecentActivity label="Recent Activity" labelColor={Color.dark}>
            {formatDate(record.lastActivityAt, dateFormat)}
          </RecentActivity>
        </Col>
      )}
    </Row>
  </>
)
