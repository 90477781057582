/**
 * Can given string be parsed as JSON or not?
 */
export const isValidJson = (value: string): boolean => {
  try {
    JSON.parse(value)
  } catch {
    return false
  }
  return true
}
