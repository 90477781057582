import { ButtonType } from 'antd/lib/button'
import AntModal, { ModalProps as AntModalProps } from 'antd/lib/modal/Modal'
import React, { FC } from 'react'
import XIcon from 'react-feather/dist/icons/x'
import styled from 'styled-components'

import { BorderRadius, Color, Spacing } from '../styles'
import { Button, ButtonProps } from './Button'

export const MODAL_BUTTON_HEIGHT = '40px'

export const MODAL_BUTTON_STYLE = {
  minHeight: MODAL_BUTTON_HEIGHT,
  paddingLeft: Spacing.md24,
  paddingRight: Spacing.md24,
}

const StyledModal = styled(AntModal)({
  '.ant-modal-close-x': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  '.ant-modal-content, .ant-modal-header': {
    borderRadius: BorderRadius.sm,
    '.ant-modal-title': {
      color: Color.dark,
    },
  },
  '.ant-modal-footer': {
    padding: Spacing.sm16,
  },
})

export interface ModalProps extends AntModalProps {
  cancelButtonProps?: ButtonProps
  okButtonProps?: ButtonProps
}

export const Modal: FC<ModalProps> = ({
  cancelButtonProps,
  cancelText,
  footer,
  okButtonProps,
  okText,
  okType,
  ...rest
}) => (
  <StyledModal
    centered
    closeIcon={<XIcon color={Color.lightGray} height={20} width={20} />}
    footer={
      footer === undefined ? (
        <>
          <Button
            style={MODAL_BUTTON_STYLE}
            type="ghost"
            onClick={rest.onCancel}
            {...cancelButtonProps}
          >
            {cancelText ?? 'Cancel'}
          </Button>

          <Button
            style={{
              ...MODAL_BUTTON_STYLE,
              marginLeft: 0,
              ...okButtonProps?.style,
            }}
            type={(okType as ButtonType | undefined) ?? 'primary'}
            onClick={rest.onOk}
            {...okButtonProps}
          >
            {okText ?? 'Submit'}
          </Button>
        </>
      ) : (
        footer
      )
    }
    {...rest}
  />
)
