import { createElement, FC, ReactHTML, ReactNode, useState } from 'react'

export interface HoveredProps {
  children: ReactNode
  active: ReactNode
  component?: keyof ReactHTML
}

export const Hovered: FC<HoveredProps> = ({
  children,
  active,
  component = 'span',
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return createElement(
    component,
    {
      onMouseEnter: () => {
        setIsHovered(true)
      },
      onMouseLeave: () => {
        setIsHovered(false)
      },
    },
    isHovered ? active : children,
  )
}
