import AntInput, { PasswordProps } from 'antd/lib/input'
import Row from 'antd/lib/row'
import React, { forwardRef, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { BorderRadius, Spacing } from '..'

const StyledRow = styled(Row)<{ $fullWidth: boolean }>`
  ${(props) =>
    props.$fullWidth &&
    css`
      width: 100%;
    `}
`

const Label = styled.label`
  color: ${({ theme }) => theme.color};
  margin-bottom: ${Spacing.sm8};
`

const StyledInput = styled(AntInput)`
  border-radius: ${BorderRadius.xs};
`

const StyledPassword = styled(AntInput.Password)`
  border-radius: ${BorderRadius.xs};
`

export interface InputProps extends PasswordProps {
  label?: ReactNode
  fullWidth?: boolean
}

export const Input = forwardRef<AntInput, InputProps>(
  ({ label, type, fullWidth = false, ...rest }, ref) => (
    <StyledRow $fullWidth={fullWidth}>
      {label && <Label>{label}</Label>}
      {type === 'password' ? (
        <StyledPassword ref={ref} {...rest} />
      ) : (
        <StyledInput type={type} ref={ref} {...rest} />
      )}
    </StyledRow>
  ),
)

Input.displayName = 'Input'
