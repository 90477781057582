import React, { FC, ReactNode } from 'react'
import { ReactMarkdownPropsBase } from 'react-markdown'
import ReactMarkdownWithHtml from 'react-markdown/with-html'
import gfm from 'remark-gfm'
import styled, { css } from 'styled-components'

import {
  BorderRadius,
  Color,
  colorWithOpacity,
  FontSize,
  FontWeight,
  Size,
  Spacing,
} from '../'
import { generateTextColors } from '../functions'
import { media } from '../media'

interface TypographyProps {
  $color?: string
  $fontSize?: string
  $fontWeight?: number
}

export interface MarkdownProps extends ReactMarkdownPropsBase, TypographyProps {
  markdown: string
  prefix?: ReactNode
}

export interface MarkdownWrapperProps extends TypographyProps {
  $hasPrefix: boolean
}

const DOT_SIZE = '7px'

const MarkdownWrapper = styled.div<MarkdownWrapperProps>`
  .markdown-html {
    ${generateTextColors()};

    color: ${(props) => (props.$color ? props.$color : Color.dark)};
    font-size: ${(props) => (props.$fontSize ? props.$fontSize : FontSize.md)};
    font-weight: ${(props) => props.$fontWeight && props.$fontWeight};

    span[class='color-black'] span.markdown-text {
      padding: ${Spacing.sm8} 0;
      margin: 0 -${Spacing.sm8};
    }

    & > ul {
      padding: 0;

      li {
        position: relative;

        span.markdown-text {
          display: block;
          padding-left: ${Spacing.md40};
        }

        &::marker {
          color: transparent;
        }

        &::before {
          display: 'block';
          content: '';
          width: ${DOT_SIZE};
          height: ${DOT_SIZE};
          background: ${Color.blue};
          border-radius: ${BorderRadius.circle};
          position: absolute;
          top: ${Spacing.sm10};
          left: ${Spacing.sm16};
          z-index: 2;
        }
      }
    }

    li > ul {
      li {
        span.markdown-text {
          padding-left: ${Spacing.md32};
        }

        &::before {
          display: 'block';
          content: '';
          width: 5px;
          height: 5px;
          background: inherit;
          border: 1px solid ${Color.blue};
          border-radius: ${BorderRadius.circle};
          position: absolute;
          top: ${Spacing.sm12};
          left: ${Spacing.sm16};
          z-index: 2;
        }
      }
    }

    h1 {
      text-align: center;
      color: ${(props) => props.$color ?? Color.dark};
      font-size: ${FontSize.lg};
      font-weight: ${FontWeight.extraBold};
      margin-bottom: 0;

      ${media.up(Size.md)} {
        font-size: calc(${FontSize.md} * 3);
      }
    }

    .single-row {
      span.markdown-text {
        display: block;
      }
    }

    .block {
      .markdown-text {
        background: unset;
        border-left: none;

        svg {
          left: -${Spacing.md32};
        }
      }

      span:first-child {
        padding-left: 0;
      }
    }

    div[class^='alert-'] {
      padding: ${Spacing.sm16} ${Spacing.md24};
      border-radius: ${BorderRadius.md};
      margin-top: ${Spacing.md32};
      margin-bottom: ${Spacing.md32};
      font-size: ${FontSize.sm};

      &.alert-info {
        background: ${Color.lightBlue};
        color: ${Color.blue};
      }

      &.alert-success {
        background: ${colorWithOpacity(Color.green, 20)};
        color: ${Color.green};
      }

      &.alert-warning {
        background: ${colorWithOpacity(Color.orange, 20)};
        color: ${Color.orange};
      }

      &.alert-error {
        background: ${colorWithOpacity(Color.red, 20)};
        color: ${Color.red};
      }
    }
  }

  ${(props) =>
    props.$hasPrefix &&
    css`
      display: flex;

      .markdown-html {
        margin-left: -${Spacing.md32};

        & > * {
          padding-left: ${Spacing.md32} !important;
        }

        svg {
          left: -${Spacing.lg56} !important;
        }
      }
    `}

  strong {
    font-weight: ${FontWeight.bold};
  }

  strong > .content-title {
    font-size: ${FontSize.lg};
  }
`

export const Markdown: FC<MarkdownProps> = ({
  markdown,
  $color,
  $fontSize,
  $fontWeight,
  prefix,
  ...rest
}) => {
  const renderers = {
    paragraph: function ParagraphRender({
      children,
    }: {
      children: ReactNode[]
    }) {
      return (
        <div className={children.length === 1 ? 'single-row' : 'block'}>
          {children}
        </div>
      )
    },
    heading: function HeadingRender({ children }: { children: ReactNode[] }) {
      return (
        <h1 className={children.length === 1 ? 'single-row' : 'block'}>
          {children}
        </h1>
      )
    },
    text: function TextRender({ value }: { value: string }) {
      return <span className="markdown-text">{value}</span>
    },
  }

  return (
    <MarkdownWrapper
      $color={$color}
      $fontSize={$fontSize}
      $fontWeight={$fontWeight}
      $hasPrefix={!!prefix}
    >
      {prefix}
      <ReactMarkdownWithHtml
        plugins={[gfm]}
        allowDangerousHtml
        renderers={renderers}
        className="markdown-html"
        {...rest}
      >
        {markdown}
      </ReactMarkdownWithHtml>
    </MarkdownWrapper>
  )
}
