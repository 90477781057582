import createBreakpoint from 'react-use/lib/factory/createBreakpoint'

import { Size } from '..'

export interface UseBreakpoint {
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
  isTabletVertical: boolean
  isTabletHorizontal: boolean
}

const convertToBreakpoint = (size: string): number =>
  Number(size.replace('px', '')) + 1

const useBreakpointString = createBreakpoint({
  desktop: convertToBreakpoint(Size.lg),
  tabletHorizontal: convertToBreakpoint(Size.md),
  tabletVertical: convertToBreakpoint(Size.sm),
  mobile: 0,
})

export const useBreakpoint = (): UseBreakpoint => {
  const breakpoint = useBreakpointString()

  const isDesktop = breakpoint === 'desktop'
  const isMobile = breakpoint === 'mobile'
  const isTabletVertical = breakpoint === 'tabletVertical'
  const isTabletHorizontal = breakpoint === 'tabletHorizontal'
  const isTablet = isTabletVertical || isTabletHorizontal

  return {
    isDesktop,
    isTablet,
    isMobile,
    isTabletVertical,
    isTabletHorizontal,
  }
}
