import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'

export interface SensitiveContentBag {
  isBlurred: boolean
  onShow?: (evt: SyntheticEvent) => void
}

export interface SensitiveContentProps extends HTMLAttributes<HTMLDivElement> {
  timeout: number
  children: (sensitiveContentBag: SensitiveContentBag) => ReactNode
}

export const SensitiveContent: FC<SensitiveContentProps> = ({
  timeout,
  children,
}) => {
  const [isBlurred, setIsBlurred] = useState<boolean>(true)
  const [timer, setTimer] = useState<number | undefined>()
  const [oldOnBlur, setOldOnBlur] = useState<() => void>(() => undefined)

  const setNewTimer = (): void => {
    clearTimeout(timer)
    const newTimer = window.setTimeout(() => {
      setIsBlurred(true)
    }, timeout)
    setTimer(newTimer)
  }

  const onBlur = useCallback((): void => {
    clearTimeout(timer)
    setIsBlurred(true)
  }, [timer])

  const cleanUp = useCallback((): void => {
    window.removeEventListener('blur', onBlur)
    clearTimeout(timer)
  }, [timer])

  const onShow = (): void => {
    setNewTimer()
    setIsBlurred(false)
  }

  useEffect(() => {
    window.addEventListener('blur', onBlur)
  }, [])

  useEffect(() => cleanUp, [cleanUp])

  useEffect(() => {
    if (oldOnBlur !== onBlur) {
      window.removeEventListener('blur', oldOnBlur)
      window.addEventListener('blur', onBlur)
      setOldOnBlur(() => onBlur)
    }
  }, [onBlur, oldOnBlur])

  return <div>{children({ isBlurred, onShow })}</div>
}
