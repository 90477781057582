import { FC, ReactElement } from 'react'

import { ApiError } from '../errors'
import { ApiResponseBag, isApiDataBag, isErrorBag } from '../hooks'

export interface RenderProps {
  renderError: (error: ApiError) => ReactElement
  renderLoading: () => ReactElement
}

export interface CreateResponseType extends RenderProps {
  captureException: (error: ApiError) => void
}

export type ResponseProps<T> = ApiResponseBag<T> &
  Partial<RenderProps> & {
    children: (apiData: T) => ReactElement
  }

export const createResponseHoc = ({
  captureException,
  ...defaults
}: CreateResponseType): FC<ResponseProps<any>> => {
  const defaultRenderLoading = defaults.renderLoading
  const defaultRenderError = defaults.renderError

  /**
   * High Order Component to deal with API Response.
   *
   * In case of loading stage - render tree from `renderLoading` prop (or from
   * default `renderLoading`).
   *
   * In case of error state - render tree from `renderError` prop, which
   * receives `error` instance (or from default `renderError`).
   *
   * Finally, in case of success state - render tree from `children` prop.
   */
  return function Response<T>(bag: ResponseProps<T>): ReactElement {
    if (isErrorBag(bag)) {
      const { error, renderError } = bag
      captureException(error)
      return renderError ? renderError(error) : defaultRenderError(error)
    }

    if (isApiDataBag<T>(bag)) {
      return bag.children(bag.apiData)
    }

    const { renderLoading } = bag
    return renderLoading ? renderLoading() : defaultRenderLoading()
  }
}
