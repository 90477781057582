import React, { FC, ReactNode } from 'react'
import { Helmet, HelmetProps } from 'react-helmet-async'

export type OpenGraphType = 'website' | 'article' | 'profile'

export interface PageOpenGraphType {
  url: string
  type?: OpenGraphType
  title?: string
  description?: string
  image?: {
    url: string
    alt?: string
    width?: number
    height?: number
  }
}

export type TwitterCardType =
  | 'summary'
  | 'summary_image_large'
  | 'app'
  | 'player'

export interface PageTwitterCardType {
  card?: TwitterCardType
  site: string
  title?: string
  description?: string
  image?: {
    url: string
    alt?: string
  }
}

const DEFAULT_OPEN_GRAPH_TYPE: OpenGraphType = 'website'
const DEFAULT_TWITTER_CARD: TwitterCardType = 'summary'

type MetaTagTuple = [string, string, string | undefined]

export interface PageProps extends HelmetProps {
  children: ReactNode
  description: string
  openGraph: PageOpenGraphType
  title: string
  twitterCard: PageTwitterCardType
}

export const Page: FC<PageProps> = ({
  children,
  description,
  openGraph,
  twitterCard,
  ...rest
}) => {
  const { title } = rest

  const { image: ogImage } = openGraph

  const ogTitle = openGraph.title ?? title
  const tcTitle = twitterCard.title ?? title

  const metaTags: MetaTagTuple[] = [
    ['name', 'description', description],
    ['property', 'og:type', openGraph.type ?? DEFAULT_OPEN_GRAPH_TYPE],
    ['property', 'og:url', openGraph.url],
    ['property', 'og:title', ogTitle],
    ['property', 'og:description', openGraph.description ?? description],
    ['property', 'og:image', ogImage ? ogImage.url : undefined],
    ['property', 'og:image:alt', ogImage ? ogImage.alt ?? ogTitle : undefined],
    [
      'property',
      'og:image:width',
      ogImage?.width && ogImage?.height ? ogImage.width.toString() : undefined,
    ],
    [
      'property',
      'og:image:height',
      ogImage?.width && ogImage?.height ? ogImage.height.toString() : undefined,
    ],
    ['name', 'twitter:card', twitterCard.card ?? DEFAULT_TWITTER_CARD],
    ['name', 'twitter:site', twitterCard.site],
    ['name', 'twitter:title', twitterCard.title ?? title],
    ['name', 'twitter:description', twitterCard.description ?? description],
    [
      'name',
      'twitter:image',
      twitterCard.image ? twitterCard.image.url : undefined,
    ],
    [
      'name',
      'twitter:image:alt',
      twitterCard.image ? twitterCard.image.alt ?? tcTitle : undefined,
    ],
  ]

  return (
    <>
      <Helmet {...rest}>
        {metaTags
          .filter(([key, value]) => value !== undefined)
          .map(([prop, key, value]) => (
            <meta key={key} content={value} {...{ [prop]: key }} />
          ))}
      </Helmet>

      {children}
    </>
  )
}
