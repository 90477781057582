import * as Sentry from '@sentry/react'
import type { RouterHistory } from '@sentry/react/types/reactrouter'
import { Integrations } from '@sentry/tracing'
import { ErrorInfo } from 'react'

import { Env, getEnvVar } from './env'

export interface SentrySettings {
  dsn?: string
  release?: string
  environment?: string
  sampleRate?: string
  tracesSampleRate?: string
  debug?: string
}

/**
 * Internal function to expect given value to be a float number
 */
const expectFloat = (value: string | undefined, defaultValue: number): number =>
  value ? parseFloat(value) || defaultValue : defaultValue

/**
 * Shortcut function to return `ErrorInfo` object if `componentStack` is not
 * undefined and `isProduction` is falsy.
 */
export const getErrorInfo = (
  componentStack: string | null | undefined,
  isProduction: boolean,
): ErrorInfo | undefined => {
  if (isProduction) return undefined
  if (componentStack) return { componentStack }
  return undefined
}

/**
 * Read Sentry settings from the environment.
 */
export const getSentrySettings = (env: Env): SentrySettings => ({
  dsn: getEnvVar(env, 'SENTRY_DSN'),
  release: getEnvVar(env, 'SENTRY_RELEASE'),
  environment: getEnvVar(env, 'SENTRY_ENVIRONMENT'),
  sampleRate: getEnvVar(env, 'SENTRY_SAMPLE_RATE'),
  tracesSampleRate: getEnvVar(env, 'SENTRY_TRACES_SAMPLE_RATE'),
  debug: getEnvVar(env, 'SENTRY_DEBUG'),
})

/**
 * Sentry is enabled, when `SENTRY_DSN` is available in environment.
 */
export const isSentryEnabled = (env: Env): boolean =>
  getEnvVar(env, 'SENTRY_DSN') !== undefined

/**
 * Setup Sentry integration for frontend project.
 */
export const setupSentry = (env: Env, history?: RouterHistory): void => {
  if (isSentryEnabled(env)) {
    const { dsn, release, environment, sampleRate, tracesSampleRate, debug } =
      getSentrySettings(env)

    Sentry.init({
      dsn,
      debug: debug ? debug === '1' || debug.toLowerCase() === 'y' : undefined,
      release,
      environment: environment ?? 'dev',
      sampleRate: expectFloat(sampleRate, 1.0),
      integrations: [
        history
          ? new Integrations.BrowserTracing({
              routingInstrumentation:
                Sentry.reactRouterV5Instrumentation(history),
            })
          : new Integrations.BrowserTracing(),
      ],
      tracesSampleRate: expectFloat(tracesSampleRate, 0.0),
    })
  }
}
