import AntText, { TextProps as AntTextProps } from 'antd/lib/typography/Text'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Color, FontSize, FontWeight, LineHeight } from '../..'

export interface TextProps extends AntTextProps {
  $small?: boolean
  $textAlign?: string
}

const StyledText = styled(AntText)<TextProps>`
  color: ${({ theme }) => theme.color};
  font-size: ${({ $small }) => ($small ? FontSize.xs : FontSize.sm)};
  font-style: normal;
  font-weight: ${FontWeight.normal};
  line-height: ${LineHeight.sm};
  letter-spacing: 0.01em;

  ${(props) =>
    props.$textAlign &&
    css`
      display: block;
      text-align: ${props.$textAlign};
    `}

  &.ant-typography-secondary {
    color: ${Color.darkGray};
  }

  strong {
    font-weight: ${FontWeight.bold};
  }
`

export const Text: FC<TextProps> = (props) => <StyledText {...props} />
