import notification, {
  ConfigProps as SystemNotificationsProps,
} from 'antd/lib/notification'
import React, { FC } from 'react'
import { createGlobalStyle } from 'styled-components'

import { Color } from '..'

const StyledSystemNotifications = createGlobalStyle`
  .ant-notification-notice-message,
  .ant-notification-notice-close,
  .ant-notification-notice-description {
    color: ${Color.white};
    margin-left: 0 !important;
  }

  .ant-notification-notice-icon {
    display: none;
  }
`

export { SystemNotificationsProps }

export const SystemNotifications: FC<SystemNotificationsProps> = (props) => {
  notification.config({
    ...props,
    placement: props.placement ?? 'bottomRight',
  })
  return <StyledSystemNotifications />
}
