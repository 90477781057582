import Menu from 'antd/lib/menu'
import React, { FC, useContext } from 'react'
import { Link } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'

import { BorderRadius, Color, colorWithOpacity, Spacing } from '../../styles'
import { MenuItemType } from '../../types'

export interface SiderMenuItemsProps {
  menuItems: MenuItemType[]
  selectedKeys: string[]
}

const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  border-radius: ${BorderRadius.xs};
  color: ${({ theme }) => theme.sider.color};
  padding: 0 ${Spacing.sm16} !important;
  margin: 0 !important;

  &.ant-menu-item-selected {
    background: ${colorWithOpacity(Color.blue, 20)} !important;
    color: ${Color.white};

    > .anticon {
      color: ${Color.blue};
    }
  }
`

export const SiderMenuItems: FC<SiderMenuItemsProps> = ({
  menuItems,
  selectedKeys,
}) => {
  const theme = useContext(ThemeContext)

  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      theme={theme.sider.name}
      style={{ background: theme.sider.backgroundColor }}
    >
      {menuItems.map((item) => (
        <StyledMenuItem
          id={`sider-menu-item-${item.key}`}
          key={item.key}
          icon={
            item.linkProps ? (
              <span className="anticon" role="img">
                {item.icon}
              </span>
            ) : (
              <span className="anticon" role="img" onClick={item.onClick}>
                {item.icon}
              </span>
            )
          }
        >
          {item.linkProps ? (
            <Link {...item.linkProps} />
          ) : (
            <span onClick={item.onClick}>{item.label}</span>
          )}
        </StyledMenuItem>
      ))}
    </Menu>
  )
}
