import AntTable, { TableProps as AntTableProps } from 'antd/lib/table'
import React, { FC } from 'react'
import { createGlobalStyle, css } from 'styled-components'

import { Color, FontWeight, Size } from '../'
import { media } from '../media'
import { BoxShadow } from '../styles'

const Styled = createGlobalStyle<{ isResponsive: boolean }>`
  .ant-table-thead > tr > th  {
    color: ${Color.darkGray};
    font-weight:${FontWeight.bold};
    background: none;
  }

  .ant-table {
    overflow: auto;
  }

  .ant-table-tbody > tr > td {
    color: ${Color.gray};

    .ant-badge-status-text {
      color: ${Color.gray};
    }
  }

  .name-column {
    color: ${Color.gray};
    font-weight: ${FontWeight.bold} ;
  }

  td:last-of-type, th:last-of-type {
    text-align: center;
  }

  ${(props) =>
    props.isResponsive &&
    css`
      ${media.down(Size.xl)} {
        .ant-table {
          &:not(.ant-table-empty) {
            table {
              width: 1100px !important;
            }
            tr {
              td:last-of-type,
              th:last-of-type {
                width: 80px;
                background: ${Color.white};
                box-shadow: ${BoxShadow.table};
                position: sticky;
                right: 0;

                button {
                  position: static;
                }
              }
            }
          }
        }
      }
    `}
`

export interface TableProps<T> extends AntTableProps<T> {
  isResponsive?: boolean
}

export const Table: FC<TableProps<any>> = ({
  dataSource,
  columns,
  isResponsive = false,
  ...rest
}) => (
  <>
    <Styled isResponsive={isResponsive} />
    <AntTable
      columns={columns}
      dataSource={dataSource}
      showHeader={dataSource && dataSource.length > 0}
      {...rest}
    />
  </>
)
