import 'styled-components'

import { SiderTheme as ThemeName } from 'antd/lib/layout/Sider'

import { Color } from './'

interface ShortThemeType {
  name: ThemeName

  backgroundColor: string
  color: string
  linkColor: string
  linkHoverColor: string
}

export interface ThemeType extends ShortThemeType {
  borderColor: string

  header: Omit<ShortThemeType, 'name'>
  sider: ShortThemeType
}

declare module 'styled-components' {
  export interface DefaultTheme extends ThemeType {}
}

const SIDER_THEME: ShortThemeType = {
  name: 'dark',
  backgroundColor: Color.dark,
  color: Color.lightGray,
  linkColor: Color.blue,
  linkHoverColor: Color.darkBlue,
}

const LIGHT_THEME: ThemeType = {
  name: 'light',

  backgroundColor: Color.white,
  borderColor: Color.lightGray,
  color: Color.dark,
  linkColor: Color.blue,
  linkHoverColor: Color.darkBlue,

  header: {
    backgroundColor: Color.white,
    color: Color.dark,
    linkColor: Color.blue,
    linkHoverColor: Color.darkBlue,
  },
  sider: SIDER_THEME,
}

const DARK_THEME: ThemeType = {
  name: 'dark',

  backgroundColor: Color.dark,
  borderColor: Color.lightGray,
  color: Color.light,
  linkColor: Color.lightGray,
  linkHoverColor: Color.light,

  header: {
    backgroundColor: Color.dark,
    color: Color.light,
    linkColor: Color.lightGray,
    linkHoverColor: Color.white,
  },
  sider: SIDER_THEME,
}

export { ThemeName }

export const themes = {
  light: LIGHT_THEME,
  dark: DARK_THEME,
}
