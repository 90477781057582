import { ColProps } from 'antd/lib/col'

// Content constants
export const DEFAULT_NARROW_COL_PROPS: ColProps = {
  span: 24,
  sm: {
    span: 18,
    offset: 3,
  },
  lg: {
    span: 12,
    offset: 6,
  },
}

// Date formats
export const DATE_FORMAT = 'MMMM DD, yyyy'
export const SHORT_DATE_FORMAT = 'MMM DD, y'

// Other constants
export const EMPTY_TEXT = '-'

export const SIDER_WIDTH = 250
export const SIDER_WIDTH_COLLAPSED = 70

export const IS_SIDER_COLLAPSED_KEY = 'ingram:isSiderCollapsed'
