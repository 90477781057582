import { AxiosResponse } from 'axios'
import cloneDeep from 'lodash.clonedeep'
import { mutate } from 'swr'

export const shouldRevalidate = (key: string): boolean => {
  let noCache = true
  mutate(
    key,
    (response: AxiosResponse) => {
      if (response?.data) {
        noCache = false
      }
    },
    false,
  )

  return noCache
}

export const mutateApiCache = <T>(
  key: string,
  nextData: T,
  validate = false,
): Promise<any> => mutate(key, { data: nextData }, validate)

export const getApiCache = <T>(
  key: string,
  validate: boolean,
): T | undefined => {
  let storedData: T | undefined
  mutate(
    key,
    (response: AxiosResponse) => {
      if (response?.data) {
        storedData = cloneDeep(response?.data)
      }
    },
    validate,
  )

  return storedData
}

export const updateApiCache = <T>(
  key: string,
  createNextData: (data: T | undefined) => T | undefined,
  validate = false,
): Promise<any> | undefined => {
  const prevData: T | undefined = getApiCache(key, validate)
  const nextData = createNextData(prevData)
  if (nextData) {
    return mutateApiCache(key, nextData, validate)
  }
  return undefined
}
