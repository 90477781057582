import notification, { ArgsProps } from 'antd/lib/notification'
import { ReactNode } from 'react'

import { Color } from '..'

type AntNotificationFunc = (config: ArgsProps) => void

export type SendNotificationExtraArgs = Omit<
  ArgsProps,
  'message' | 'description'
>

const sendNotificationFactory =
  (func: AntNotificationFunc, backgroundColor: string = Color.green) =>
  (
    message: ReactNode,
    description?: ReactNode,
    extra: SendNotificationExtraArgs = {},
  ) =>
    func({
      message,
      description,
      style: { ...(extra.style ?? {}), backgroundColor },
      ...extra,
    })

export const closeNotification = (key: string): void => notification.close(key)

export const sendErrorNotification = sendNotificationFactory(
  notification.error,
  Color.orange,
)
export const sendNotification = sendNotificationFactory(notification.info)
export const sendSuccessNotification = sendNotificationFactory(
  notification.warning,
  Color.blue,
)
export const sendWarningNotification = sendNotificationFactory(
  notification.success,
)
