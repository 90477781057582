export const media = {
  up: (breakpoint: string, vertical = false): string =>
    `@media (min-${vertical ? 'height' : 'width'}: ${breakpoint})`,

  down: (breakpoint: string, vertical = false): string =>
    `@media (max-${vertical ? 'height' : 'width'}: calc(${breakpoint} + 1px))`,

  between: (
    breakpointMin: string,
    breakpointMax: string,
    vertical = false,
  ): string =>
    `@media (max-${
      vertical ? 'height' : 'width'
    }:  calc(${breakpointMax} + 1px)) and (min-${
      vertical ? 'height' : 'width'
    }: calc(${breakpointMin} - 1px ))`,
}

export const matchDown = (breakpoint: string, vertical = false): boolean =>
  window.matchMedia(convertToMatchMedia(media.down(breakpoint))).matches

export const matchUp = (breakpoint: string, vertical = false): boolean =>
  window.matchMedia(convertToMatchMedia(media.up(breakpoint))).matches

export const matchBetween = (
  breakpointMin: string,
  breakpointMax: string,
  vertical = false,
): boolean =>
  window.matchMedia(
    convertToMatchMedia(media.between(breakpointMin, breakpointMax)),
  ).matches

export const convertToMatchMedia = (media: string): string =>
  media.replace('@media', '').trim()
