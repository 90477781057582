import Descriptions, {
  DescriptionsProps as AntDescriptionsProps,
} from 'antd/lib/descriptions'
import { DescriptionsItemProps as AntDescriptionsItemProps } from 'antd/lib/descriptions/Item'
import React, { FC } from 'react'
import styled from 'styled-components'

import { Color, FontWeight } from '..'

const StyledDescriptions = styled(Descriptions)<
  DescriptionsProps & { $labelColor?: string }
>`
  th.ant-descriptions-item,
  td.ant-descriptions-item {
    padding-bottom: 0;
  }

  .ant-descriptions-item-label {
    color: ${({ $labelColor }) => $labelColor ?? Color.gray};
  }

  .ant-descriptions-item-content {
    font-weight: ${FontWeight.bold};
  }
`

export type DescriptionsProps = AntDescriptionsProps &
  AntDescriptionsItemProps & { labelColor?: string }

export const Description: FC<DescriptionsProps> = ({
  children,
  label,
  labelColor,
  ...rest
}) => (
  <StyledDescriptions $labelColor={labelColor} {...rest}>
    <Descriptions.Item label={label}>{children}</Descriptions.Item>
  </StyledDescriptions>
)
