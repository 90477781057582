import Col from 'antd/lib/col'
import Layout from 'antd/lib/layout'
import { BasicProps } from 'antd/lib/layout/layout'
import Row from 'antd/lib/row'
import React, { FC } from 'react'
import styled from 'styled-components'

import { Spacing } from '../..'
import { DEFAULT_NARROW_COL_PROPS } from '../../constants'

const { Content: AntContent } = Layout

const StyledContent = styled(AntContent)`
  padding: ${Spacing.md32} ${Spacing.lg48} 0;
`

export interface ContentProps extends BasicProps {
  narrow?: boolean
}

export const Content: FC<ContentProps> = ({ children, narrow, ...rest }) => {
  let content = children

  if (narrow) {
    content = (
      <Row>
        <Col {...DEFAULT_NARROW_COL_PROPS}>{children}</Col>
      </Row>
    )
  }

  return <StyledContent {...rest}>{content}</StyledContent>
}
