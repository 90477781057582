import Row from 'antd/lib/row'
import AntSelect, { SelectProps as AntSelectProps } from 'antd/lib/select'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { BorderRadius, Spacing } from '..'

const Label = styled.label`
  color: ${({ theme }) => theme.color};
  margin-bottom: ${Spacing.sm8};
`

const StyledSelect = styled(AntSelect)`
  width: 100%;

  .ant-select-selector {
    border-radius: ${BorderRadius.xs} !important;
  }
`

export interface SelectProps extends AntSelectProps<any> {
  label?: ReactNode
}

export const { Option } = AntSelect

export const Select: FC<SelectProps> = ({ label, children, ...rest }) => (
  <Row>
    {label && <Label>{label}</Label>}
    <StyledSelect dropdownStyle={{ padding: Spacing.sm8 }} {...rest}>
      {children}
    </StyledSelect>
  </Row>
)
