import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { SHORT_DATE_FORMAT } from '../constants'
import { FontWeight, Spacing } from '../styles'
import { StatusColorType } from '../types'
import { AssessmentProgress } from './AssessmentProgress'
import { Card, CardProps } from './Card'
import { Tag } from './Tag'
import { Title } from './Typography'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export interface AssessmentStatusRecord {
  color: StatusColorType | string
  label: ReactNode
}

export interface AssessmentRecord {
  uid: string
  assessmentType: ReactNode
  status: AssessmentStatusRecord
  progress: number
  currentSection?: string
  createdAt: Date
  lastActivityAt?: Date
}

export interface AssessmentCardProps extends CardProps {
  dateFormat?: string
  record: AssessmentRecord
}

export const AssessmentCard: FC<AssessmentCardProps> = ({
  dateFormat = SHORT_DATE_FORMAT,
  record,
  children,
  ...rest
}) => (
  <Card {...rest}>
    <Container
      style={{
        marginBottom: record.progress === 100 ? Spacing.md32 : Spacing.sm16,
      }}
    >
      <Title level={5}>{record.assessmentType}</Title>
      <Tag color={record.status.color} $fontWeight={FontWeight.bold}>
        {record.status.label}
      </Tag>
    </Container>
    <AssessmentProgress record={record} dateFormat={dateFormat} />
    {children}
  </Card>
)
