import AntButton, { ButtonProps as AntButtonProps } from 'antd/lib/button'
import React, { FC, forwardRef } from 'react'
import styled, { css } from 'styled-components'

import {
  BorderRadius,
  BoxShadow,
  Color,
  colorWithOpacity,
  Size,
  Spacing,
} from '..'
import { media } from '../media'

export interface ButtonProps extends AntButtonProps {
  $boxShadow?: boolean
}

const StyledButton = styled(AntButton)<ButtonProps>`
  border-radius: ${BorderRadius.xs};
  box-shadow: ${({ $boxShadow }) => ($boxShadow ? BoxShadow.regular : 'none')};
  margin-right: ${Spacing.sm12};

  &.lucid {
    align-items: center;
    background-color: ${Color.lightBlue} !important;
    border: none;
    display: flex;
    justify-content: center;
  }

  &.table {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  &:last-child {
    margin-right: 0;
  }

  ${media.down(Size.sm)} {
    padding: 0 ${Spacing.sm4};
  }

  ${(props) =>
    props.type === 'primary' &&
    css`
      border-color: ${colorWithOpacity(Color.blue, 80)};
      background-color: ${colorWithOpacity(Color.blue, 80)};

      &:hover {
        background-color: ${Color.blue};
        border-color: ${Color.blue};
      }
    `}

  ${(props) =>
    (['default', 'ghost', 'dashed'].includes(props.type!) ||
      (props.type === 'primary' && props.ghost)) &&
    css`
      border-color: ${Color.flowerBlue} !important;
      color: ${Color.flowerBlue} !important;

      &:hover {
        border-color: ${Color.blue} !important;
        color: ${Color.blue} !important;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}

  ${(props) =>
    props.disabled &&
    !['default', 'ghost', 'dashed'].includes(props.type!) &&
    css`
      background-color: ${Color.blue} !important;
      color: ${Color.white} !important;
    `}
`

export const Button: FC<ButtonProps> = forwardRef<
  HTMLInputElement,
  ButtonProps
>(({ size = 'middle', type = 'primary', $boxShadow = false, ...rest }, ref) => (
  <StyledButton
    $boxShadow={$boxShadow}
    ref={ref}
    size={size}
    type={type}
    {...rest}
  />
))

Button.displayName = 'Button'
