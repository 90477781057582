import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined'
import Badge from 'antd/lib/badge'
import Popover, { PopoverProps } from 'antd/lib/popover'
import Row from 'antd/lib/row'
import React, { FC, useEffect, useState } from 'react'
import Bell from 'react-feather/dist/icons/bell'
import { Link, LinkProps } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

import { BorderRadius, Color, FontSize, FontWeight, Size, Spacing } from '..'
import { formatPastDistance } from '../functions'
import { media } from '../media'
import { Button } from './Button'
import { Text } from './Typography'

const Styled = createGlobalStyle`
  .user-notifications .ant-popover-content {
    min-width: 435px;
  }

  .ant-popover-inner {
    border-radius: ${BorderRadius.xs};
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    border-color: ${Color.blue} !important;
    background: ${Color.blue};
  }

  .ant-badge-dot {
    background: ${Color.orange};
  }

  ${media.down(Size.sm)} {
    .ant-popover-content {
      min-width: 80vw;
      max-width: 80vw;
    }
  }

  sup.ant-badge-dot {
    top: ${Spacing.sm4};
    right: ${Spacing.sm4};
    width: ${Spacing.sm8};
    height: ${Spacing.sm8};
  }
`

const Header = styled.div`
  background: ${Color.blue};
  font-weight: ${FontWeight.bold};
  color: white;
  display: flex;
  justify-content: center;
  padding: ${Spacing.sm8} 0;
  border-top-right-radius: ${BorderRadius.xs};
  border-top-left-radius: ${BorderRadius.xs};
`

const Notifications = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 2rem;
  max-height: 16rem;
`

const Notification = styled(Row)`
  padding: ${Spacing.sm16};
  color: ${Color.gray};
  border-bottom: 1px solid ${Color.lightestGray};
  font-size: ${FontSize.xs};
`

const Name = styled.div`
  color: ${Color.dark};
  margin-right: ${Spacing.sm4};
  font-weight: ${FontWeight.bold};
`

const Message = styled.div`
  margin-right: 80px;

  ${media.down(Size.sm)} {
    margin-right: 0;
  }
`

const Time = styled.div`
  color: ${Color.darkGray};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${Spacing.sm8} 0;

  button {
    width: 90%;
  }
`

const StyledText = styled(Text)`
  text-align: center;
  padding-top: ${Spacing.sm4};
`

export interface UserNotificationActorRecord {
  uid: string
  fullName: string
}

export interface UserNotificationRecord {
  uid: string
  actor?: UserNotificationActorRecord
  message: string
  createdAt: Date
}

export interface UserNotificationsProps extends PopoverProps {
  records: UserNotificationRecord[]
  linkProps: LinkProps
  onMarkRead: () => void
}

export const UserNotifications: FC<UserNotificationsProps> = ({
  records,
  linkProps,
  onMarkRead,
  ...rest
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [hasBadge, setHasBadge] = useState<boolean>(false)

  useEffect(() => {
    if (records.length > 0) {
      setHasBadge(true)
    }
  }, [records])

  const handleVisibleChange = (visibility: boolean): void => {
    if (visibility) {
      setHasBadge(false)
    }

    setVisible(visibility)

    setTimeout(() => {
      if (!visibility) {
        onMarkRead()
      }
    }, 300)
  }

  return (
    <Popover
      overlayClassName="user-notifications"
      arrowPointAtCenter
      content={
        <div>
          <Header>Your notifications</Header>

          <Notifications>
            {records.length > 0 ? (
              records.map((record) => (
                <Notification
                  key={record.uid}
                  justify="space-between"
                  align="middle"
                >
                  <Row>
                    {record.actor && <Name>{record.actor.fullName}</Name>}
                    <Message>{record.message}</Message>
                  </Row>
                  <Time>
                    <ClockCircleOutlined style={{ marginRight: Spacing.sm4 }} />
                    {formatPastDistance(record.createdAt)}
                  </Time>
                </Notification>
              ))
            ) : (
              <StyledText type="secondary">
                You do not have any new notifications
              </StyledText>
            )}
          </Notifications>

          <ButtonContainer>
            <Button type="primary" ghost>
              <Link {...linkProps} />
            </Button>
          </ButtonContainer>
        </div>
      }
      placement="bottomRight"
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      {...rest}
    >
      <Styled />

      <Badge dot={hasBadge}>
        <Bell color={Color.lightGray} width={20} height={20} />
      </Badge>
    </Popover>
  )
}
