import { ApiError, isAxiosError, isErrorResponse } from './errors'

/**
 * If Error catched - it should be captured by Sentry.
 *
 * If AxiosError catched and it contains response and response data is not a
 * valid error response object - it should be captured by Sentry. If response
 * contains valid JSON, it means server already acknowledge on the error.
 */
export const shouldCaptureException = (error: ApiError): boolean => {
  if (!isAxiosError(error)) return true
  return error.response ? !isErrorResponse(error.response.data) : false
}
