import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestTransformer,
  AxiosResponseTransformer,
} from 'axios'
import qs from 'qs'
import { v4 as uuidv4 } from 'uuid'

export const camelCase = (value: string): string =>
  value.replace(/_([a-z])/g, (item) => item[1].toUpperCase()).replace('_', '')

export const isFormData = (value: any): value is FormData =>
  typeof FormData !== 'undefined' && value instanceof FormData

export const isObject = (value: any): boolean => {
  const type = typeof value
  return value !== null && (type === 'object' || type === 'function')
}

export const isString = (value: any): value is string =>
  typeof value === 'string'

export const snakeCase = (value: string): string =>
  value.replace(/([A-Z])/g, (item) => `_${item[0].toLowerCase()}`)

export const transformKeys = (
  data: any,
  transformer: (value: string) => string,
): any => {
  // Do not transform FormData
  if (isFormData(data)) {
    return data
  }

  // Transform all values in array
  if (Array.isArray(data)) {
    return data.map((item) => transformKeys(item, transformer))
  }

  // Transform all keys in object
  if (isObject(data)) {
    return Object.fromEntries(
      Object.keys(data).map((key) => [
        transformer(key),
        transformKeys(data[key], transformer),
      ]),
    )
  }

  // If this is not a FormData, nor Array, or Object - return value as is
  return data
}

export const DEFAULT_AXIOS_CONFIG: AxiosRequestConfig = {
  headers: {
    'X-Client-UID': uuidv4(),
    'X-Requested-With': 'XMLHttpRequest',
  },
  paramsSerializer: (params) => qs.stringify(transformKeys(params, snakeCase)),
  responseType: 'json',
  timeout: 30000,
  transformRequest: [
    (data) => transformKeys(data, snakeCase),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data) => transformKeys(data, camelCase),
  ],
}

export const createApi = (extra: AxiosRequestConfig = {}): AxiosInstance =>
  axios.create({
    ...DEFAULT_AXIOS_CONFIG,
    ...extra,
  })
