import AntTag, { TagProps } from 'antd/lib/tag'
import styled from 'styled-components'

import { BorderRadius, Color, FontWeight } from '..'

export { TagProps }

export const Tag = styled(AntTag)<TagProps & { $fontWeight?: number }>`
  border: none;
  border-radius: ${BorderRadius.xs};
  color: ${Color.white};
  font-weight: ${({ $fontWeight }) => $fontWeight ?? FontWeight.normal};

  &.ant-tag-success {
    background-color: ${Color.blue};
  }

  &.ant-tag-processing {
    background-color: ${Color.orange};
  }

  &.ant-tag-error {
    background-color: ${Color.red};
  }

  &:last-child {
    margin-right: 0;
  }
`
