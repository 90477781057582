import { AxiosError } from 'axios'

import { camelCase, isObject, isString } from './api'
import { isValidJson } from './json'
import {
  DefaultResponseRecord,
  ErrorResponseRecord,
  StringOrNumber,
  ValidationErrorItemLoc,
  ValidationErrorResponseRecord,
} from './records'

const IGNORE_LOC_PREFIXES: string[] = [
  'body.payload.',
  'body.query.',
  'response.',
]

export type ApiError = Error | AxiosError

export const convertValidationErrorItemLocToString = (
  loc: ValidationErrorItemLoc,
): string => {
  const value = loc
    .map((item: StringOrNumber): string => camelCase(String(item)))
    .join('.')

  for (const prefix of IGNORE_LOC_PREFIXES) {
    if (value.startsWith(prefix)) {
      return value.slice(prefix.length)
    }
  }

  return value
}

export const isAxiosError = (value: ApiError): value is AxiosError =>
  (value as AxiosError).isAxiosError !== undefined

export const isErrorResponse = (
  rawValue: any,
): rawValue is ErrorResponseRecord => {
  let value
  if (isString(rawValue) && isValidJson(rawValue)) {
    value = JSON.parse(rawValue)
  } else if (isObject(rawValue) && !Array.isArray(rawValue)) {
    value = rawValue
  } else {
    return false
  }

  if ((value as DefaultResponseRecord).detail) {
    return isString(value.detail) || isValidationErrorResponse(value)
  }

  return false
}

export const isValidationErrorResponse = (
  value: ErrorResponseRecord,
): value is ValidationErrorResponseRecord => Array.isArray(value.detail)

export const convertErrorDataToString = (data: ErrorResponseRecord): string => {
  if (isValidationErrorResponse(data)) {
    const prefix = data.detail.length > 1 ? '- ' : ''
    return data.detail
      .map(
        ({ loc, msg }) =>
          `${prefix}**${convertValidationErrorItemLocToString(loc)}**: ${msg}`,
      )
      .join('\n')
  }
  return data.detail
}

export const convertErrorToString = (err: ApiError): string => {
  if (isAxiosError(err)) {
    if (err.response) {
      if (!isErrorResponse(err.response.data)) {
        return err.response.statusText
      }
      return convertErrorDataToString(err.response.data)
    }
    return err.toString() // eslint-disable-line @typescript-eslint/no-base-to-string
  }
  return err.toString()
}
