import { PresetStatusColorType } from 'antd/lib/_util/colors'
import { ReactNode } from 'react'
import { LinkProps } from 'react-router-dom'

export { PresetStatusColorType as StatusColorType }

export interface SelectOptionType {
  value: string
  label: ReactNode
}

export type MenuItemType =
  | {
      key: string
      linkProps: LinkProps
      onClick?: never
      label?: never
      icon: ReactNode
      selected: boolean
    }
  | {
      key: string
      linkProps?: never
      onClick: () => void
      label: string
      icon: ReactNode
      selected: boolean
    }

export interface MenuSectionType {
  name: string
  menuItems: MenuItemType[]
}
