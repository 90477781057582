import Tabs, { TabPaneProps as TabProps } from 'antd/lib/tabs'
import React, { FC } from 'react'
import { createGlobalStyle } from 'styled-components'

import { Color, FontSize, FontWeight, LineHeight } from '..'
import { Spacing } from '../styles'

const { TabPane } = Tabs

const Styled = createGlobalStyle`
  .ant-tabs-tab {
    min-width: 10rem;
    justify-content: center;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom-color: ${({ theme }) => theme.borderColor};
  }

  .ant-tabs-tab-btn {
    font-style: normal;
    font-weight: ${FontWeight.bold};
    font-size: ${FontSize.sm};
    line-height: ${LineHeight.sm};
    text-align: center;
    letter-spacing: 0.01em;

    &:hover {
      color: ${Color.darkBlue};
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Color.blue};
  }

  .ant-tabs-ink-bar {
    background: ${Color.blue};
  }

  .ant-tabs-tab {
    padding: ${Spacing.sm8} !important;
  }
`

export const Tab: FC<TabProps> = (props) => (
  <>
    <Styled />
    <TabPane {...props} />
  </>
)
