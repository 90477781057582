import AntAutoComplete, {
  AutoCompleteProps as AntAutoCompleteProps,
} from 'antd/lib/auto-complete'
import Input from 'antd/lib/input'
import { SelectProps } from 'antd/lib/select'
import React, { FC, ReactElement, useState } from 'react'
import Search from 'react-feather/dist/icons/search'
import styled, { createGlobalStyle } from 'styled-components'

import { BoxShadow, Color, Spacing } from '..'
import { SelectOptionType } from '../types'

const Styled = createGlobalStyle`
  .ant-input-wrapper, .ant-input-affix-wrapper {
    height: 100%;
  }

  .ant-input-group-addon {
    display: none;
  }

  .ant-select-dropdown .rc-virtual-list-holder {
    padding: ${Spacing.sm16};
  }

  .ant-select-item {
    padding: 0;
  }

  .ant-select-dropdown {
    box-shadow: ${BoxShadow.dropdown};
  }

  .ant-select-item-option-content a {
    color: black;
  }
`

const StyledAutocomplete = styled(AntAutoComplete)`
  width: 100%;
`

const StyledSearch = styled(Input.Search)`
  width: 100%;
  height: ${Spacing.lg48};
  padding: 0 ${Spacing.sm16};

  .ant-input-prefix {
    margin-right: ${Spacing.sm16};
  }

  .ant-input-suffix {
    display: none;
  }
`

export interface AutoCompleteResultType {
  value: string
  record: any
}

export interface AutoCompleteProps extends AntAutoCompleteProps {
  placeholder: string
  renderLabel: (record: any) => ReactElement | string
  onSearch: (query: string) => Promise<AutoCompleteResultType[]>
}

export type AutoCompleteOptionsType = SelectProps<SelectOptionType>['options']

export const AutoComplete: FC<AutoCompleteProps> = ({
  placeholder,
  renderLabel,
  onSearch,
  ...rest
}) => {
  const [options, setOptions] = useState<AutoCompleteOptionsType>([])

  const handleSearch = (value: string): void => {
    onSearch(value).then((response) => {
      setOptions(
        response.map(({ value, record }) => ({
          value,
          label: renderLabel(record),
        })),
      )
    })
  }

  return (
    <>
      <Styled />

      <StyledAutocomplete options={options} onSearch={handleSearch} {...rest}>
        <StyledSearch
          bordered={false}
          prefix={<Search color={Color.lightGray} width={16} height={16} />}
          placeholder={placeholder}
        />
      </StyledAutocomplete>
    </>
  )
}
