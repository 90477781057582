import { AxiosInstance } from 'axios'

export const deleteAuthorizationHeader = (api: AxiosInstance): void => {
  delete api.defaults.headers.common.Authorization
}

export const setAuthorizationHeader = (
  api: AxiosInstance,
  accessToken: string,
): void => {
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
}
