import { FC, ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop: FC = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    if (!location.hash) {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      } catch (error) {
        window.scrollTo(0, 0)
      }
    }
  }, [location])

  return children as ReactElement
}
