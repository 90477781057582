/**
 * Environment type.
 *
 * Suitable with both `process.env` (parcel) and `__SNOWPACK_ENV__` (snowpack).
 */
export type Env = Record<string, string | undefined>

/**
 * Shortcut for getting env var, which respects any bundler.
 *
 * In case of working in Snowpack context, first try to find
 * `SNOWPACK_PUBLIC_ENV_VAR`, only after fallback to regular `ENV_VAR`.
 */
export const getEnvVar = (env: Env, name: string): string | undefined => {
  const snowpackName = `SNOWPACK_PUBLIC_${name}`
  return env[snowpackName] !== undefined ? env[snowpackName] : env[name]
}

/**
 * Return current mode from bundler.
 *
 * If in Snowpack context, expect `__SNOWPACK_ENV__` to be passed and return
 * value of `__SNOWPACK_ENV__.MODE`, for `process.env` return good old
 * `process.env.NODE_ENV`.
 */
export const getMode = (env: Env): string => env.MODE ?? env.NODE_ENV!

/**
 * Shortcut to check that current build operates in development mode.
 */
export const isDevelopment = (env: Env): boolean =>
  getMode(env) === 'development'

/**
 * Shortcut to check that current build operates in production mode.
 */
export const isProduction = (env: Env): boolean => getMode(env) === 'production'

/**
 * Shortcut to check that code operates in test mode.
 */
export const isTest = (env: Env): boolean => getMode(env) === 'test'
